.post__meta_items {
  position: relative;
  color: #999999;
  padding-top: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
}
.post__meta_item {
  font-size: 12px;
  line-height: 16px;
  color: #999999;
}
.dotSeparator {
  margin-left: 5px;
  margin-right: 5px;
}
.post__date {
  color: #757575;
  font-size: 15px;
  letter-spacing: 0.13px;
  line-height: 20px;
  margin-bottom: 5px;
  position: absolute;
  cursor: pointer;
  right: 5px;
  margin-bottom: 0;
}