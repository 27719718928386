.card {
  padding: 10px 14px 20px 14px;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: #fff;
  box-shadow: 0 0 3px 0 rgb(51 51 51 / 18%);
}

.h3 {
  font-size: 20px;
  text-align: left;
  font-weight: 500;
  color: #141E30;
  margin-top: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.image {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.button {
  text-transform: uppercase;
  color: #fff;
  background: #c62232;
  line-height: 20px;
  padding: 9px 20px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: .1px;
  text-transform: none;
  width: 100%;
  text-align: center;
  cursor: pointer;
  transition: all.15s ease-in-out;
}

@media (min-width: 480px) {
  .h3 {
    font-size: 18px;
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  .h3 {
    text-align: center;
  }
}