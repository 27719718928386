.post {
  background-color: #fff;
  padding: 0;
  box-shadow: 0 0 3px 0 rgba(51, 51, 51, 0.18);
  margin-bottom: 20px;
}
.post:last-of-type {
  border-bottom: none;
}
.post__description {
  color: #222222;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding: 5px;
}
.post__content {
  margin-bottom: 10px;
}
.playerWrapper {
  position: relative;
  padding-top: 100%;
}
.postContent__media_image {
  box-sizing: border-box;
  vertical-align: baseline;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.post__footer {
  position: relative;
  padding: 5px;
}