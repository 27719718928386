.post__actions {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  padding: 5px;
}
.post__action {
  background-color: white;
  padding: 0;
  border-radius: 100%;
  width: 38px;
  height: 38px;
  margin-right: 10px;
  margin-bottom: 0;
  border: 1.5px solid #D8D8D8;
}
.post__action_share {
  display: flex;
  justify-content: center;
  align-items: center;
}
.post__action:hover {
  cursor: pointer;
}
.post__action:focus {
  outline: none;
}
.post__action.post__action_upvote.post__action_active {
  background-color: rgb(156, 189, 128);
  border-color: rgb(156, 189, 128);
}
.post__action.post__action_downvote.post__action_active {
  background-color: rgb(234, 65, 66);
  border-color: rgb(234, 65, 66);
}