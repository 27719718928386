.player {
  position: absolute;
  top: 0;
  left: 0;
}
.playerWrapper {
  position: relative;
  padding-top: 100%;
}
.muteContainer {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.muteContainer img {
  width: 20px;
}