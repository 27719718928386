.main_wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  background: #F5F5F5;
  min-height: 100vh;
}
.main {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  height: 100%;
  margin-top: 100px;
}
.container {
  padding: 1.5rem;
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
}
@media (min-width: 1024px) {
  .main {
    margin-top: 102px;
  }
  .container {
    padding: 1.5rem 2rem;
  }
}
