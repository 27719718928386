.socials {
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

@media (max-width: 500px) {
  .socials {
    gap: 8px;
  }
  .socials svg {
    width: 25px;
    height: 25px;
  }
}
