.header {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0 #0000,0 0 #0000,0 0 #0000,0 0 #0000,0 1px 2px 0 rgb(0 0 0/0.05);
}
.navigation {
  margin: 0 auto;
  position: relative;
  width: 100%;
}
.header_inner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 55px;
  padding: 0 1rem;
  position: relative;
}
.nav_inner {
  position: absolute;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1 0%;
  display: flex;
  top: 10px;
  right: 0;
  bottom: 0;
  left: 0; 
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.logoLink {
  z-index: 10;
  display: flex;
}
.header_cta {
  display: flex;
  justify-self: flex-end;
  align-items: center;
  margin-left: 1.5rem;
  gap: 15px;
}

.header_cta_link {
  text-transform: uppercase;
  color: #fff;
  background: #c62232;
  line-height: 20px;
  padding: 9px 12px;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: .1px;
  text-transform: none;
  width: 100%;
  text-align: center;
  cursor: pointer;
  transition: all.15s ease-in-out;
}

.subnav {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  border-top: 1px solid #e5e5e5;
  padding: 6px 1rem;
}

@media (max-width: 500px) {
  .subnav, .header_inner {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .subnav {
    justify-content: center;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .header_cta_link {
    padding: 8px;
  }

  .nav_inner {
    justify-content: flex-start;
    left: 0.5rem;
    top: 0.8rem;
  }

  .logo {
    width: 90px !important;
  }
}