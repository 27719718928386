.container {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  margin: 0 auto;
}
.col {
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 480px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.container > .row {
  display: grid;
  grid-template-columns: 250px minmax(350px, 650px);
  grid-column-gap: 15px;
  width: 100%;
  grid-row-gap: 20px;
  margin-left: 0;
  margin-right: 0;
}
@media (min-width: 1200px) {
  .container > .row {
    grid-column-gap: 30px;
  }
}
@media (max-width: 960px) {
  .container > .row {
    grid-template-columns: 250px minmax(350px, 650px);
  }
}
.container > .row > .col {
  padding: 0;
}
.btn {
  width: auto;
}
.stories-container {
  background-color: white;
  padding: 0;
  box-shadow: 0 0 3px 0 rgba(51, 51, 51, 0.18);
}
@media (max-width: 480px) {
  .col__main {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 480px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 767px) {
  .container > .row {
    grid-template-columns: 1fr;
  }
}
.row {
  display: flex;
  justify-content: center;
}
.sticky_container {
  height: 100%;
  position: relative;
}
.sticky {
  position: sticky;
  top: 120px;
}